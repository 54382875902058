<script setup lang="ts">
import { useOnline } from '@vueuse/core';

import Logo from '@mui/assets/logos/medmastery-color.svg?component';

const route = useRoute();
const { user } = useUserStore();

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

const showVerifyYourAccountModal = ref(false);

const showFlagManager = computed(() => {
  return route.query.flags !== undefined;
});

if (user && user.value?.isVerified === false) {
  showVerifyYourAccountModal.value = true;
}

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});

const config = useRuntimeConfig();
</script>

<template>
  <div class="bg-neutral-5 lg:bg-white flex flex-col h-dvh">
    <SystemMessages />

    <header class="flex justify-center relative bg-white lg:top-0 lg:inset-x-0 lg:fixed lg:justify-normal lg:bg-transparent">
      <NuxtLink
        :to="config.public.publicWebsite"
        :external="true"
      >
        <Logo class="block m-2.5 h-8 lg:m-4 lg:h-10" />
      </NuxtLink>
    </header>

    <slot />

    <FeatureFlagManager v-if="showFlagManager" />
  </div>
</template>

<style lang="scss">
.page {
  height: 100%;
}
</style>
